<template>
  <div class="team-invitation-container">
    <el-tabs
      v-model="inviteType"
      @tab-change="choiceInviteType"
    >
      <el-tab-pane
        :label="$t('team.qrcode_invitation')"
        name="qrcode"
      >
        <div
          v-loading="loading"
          class="team-qrcode"
        >
          <div class="team-qrcode-box">
            <div
              id="teamInviteQrCode"
              ref="teamInviteQrCode"
              height="200px"
              style="display: none;"
            />
            <img
              v-if="teamInfo.avatar"
              id="qrCodeIco"
              ref="qrCodeIco"
              :src="teamInfo.avatar+'?time='+new Date().getTime()"
              crossOrigin="anonymous"
              style="object-fit: contain;display: none"
            >
            <canvas
              id="canvas"
              ref="teamQrCodeCanvas"
              class="team-qrcode-canvas"
              width="200"
              height="200"
            />
            <div
              class="down-load-box"
              title="点击下载"
              @click="downQrcodeImg"
            />
            <a
              ref="qrCodedownload"
              href="#"
              download="teamInviteQrCode.png"
            />
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane
        :label="$t('team.link_invitation')"
        name="link"
      >
        <div v-loading="loading">
          <el-input
            ref="inviteLink"
            v-model="inviteLink"
            :readonly="true"
          >
            <template #append>
              <div
                class="link-copy"
                @click="inviteLinkCopy('inviteLink')"
              >{{ $t('common.copy') }}
              </div>
            </template>
          </el-input>
        </div>

      </el-tab-pane>
      <!--      <el-tab-pane
        :label="$t('team.invitation_code')"
        name="invitation-code"
      >
        <div v-loading="loading">
          <el-input
            ref="inviteCode"
            v-model="inviteCode"
            :readonly="true"
          >
            <template #append>
              <div
                class="link-copy"
                @click="inviteLinkCopy('inviteCode')"
              >{{ $t('common.copy') }}
              </div>
            </template>
          </el-input>
        </div>

      </el-tab-pane>-->
      <el-tab-pane
        :label="$t('team.mobile_invitation')"
        name="phone"
      >
        <div
          v-loading="loading"
          class="invite-phone-box"
        >
          <el-form
            ref="invitePhoneForm"
            :model="invitePhoneForm"
            label-width="100px"
            class="demo-dynamic"
          >
            <el-form-item
              v-for="(phone, index) in invitePhoneForm.phones"
              :key="phone.key"
              :label="$t('team.mobile_phone')"
              :prop="'phones.' + index + '.value'"
              :rules="[
                { required: true, message: $t('team.enter_your_mobile_phone_number'), trigger: 'blur' },
                { validator: validatePhone, trigger: ['blur', 'change'] }
              ]"
            >
              <el-col :span="18">
                <el-input
                  :id="'phone'+index"
                  v-model="phone.value"
                />
              </el-col>
              <el-col :span="6">
                <el-button
                  v-show="(index+1)===invitePhoneForm.phones.length"
                  style="margin-left:10px"
                  size="small"
                  type="success"
                  icon="Plus"
                  round
                  @click.prevent="addPhone(phone)"
                />
                <el-button
                  v-show="!((index+1)==1&&invitePhoneForm.phones.length==1)"
                  style="margin-left:10px"
                  size="small"
                  type="danger"
                  round
                  icon="Delete"
                  @click.prevent="removePhone(phone)"
                />
              </el-col>
            </el-form-item>
          </el-form>
        </div>
      </el-tab-pane>
    </el-tabs>
    <div
      v-show="inviteInfo.expiration_time"
      class="re-set-box"
    >
      邀请方式失效时间：{{ inviteInfo.expiration_time }}
      <div
        class="reset-opt"
        @click="getInviteTeamCode(1)"
      >
        <el-icon>
          <RefreshRight />
        </el-icon>
        重置
      </div>
    </div>
  </div>
</template>

<script>

import { getInviteCode, getCurrTeamInfo, inviteJoinTeam } from '@/api/team'
import QRCode from 'qrcodejs2'
import 'intl-tel-input/build/css/intlTelInput.css'
import intlTelInput from 'intl-tel-input'

export default {
  name: 'TeamInvitation',
  components: {},
  props: {
    isDialog: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      inviteType: 'qrcode',
      inviteLink: '',
      inviteCode: '',
      inviteSearch: '',
      inviteSearchUserList: [],
      invitePhoneForm: {
        phones: [{ value: '', key: Date.now() }]
      },
      inviteEmailForm: {
        emails: [{ value: '', key: Date.now() }]
      },
      teamInfo: {
        avatar: ''
      },
      qrcode: {},
      qrCodeImgIsLoad: false,
      inviteInfo: {},
      loading: false,
      saveLoading: false
    }
  },
  mounted() {
    this.getInviteTeamCode()
    this.getCurrTeamInfoData()
  },
  methods: {
    choiceInviteType() {
      if (this.inviteType === 'phone') {
        this.initTelInputSelect()
      }
    },
    inviteLinkCopy(refName) {
      this.$refs[refName].select()
      document.execCommand('Copy')
      this.$message({
        message: this.$t('team.copy_succeeded'),
        type: 'success',
        customClass: 'message_index'
      })
    },
    inviteJoinTeamSubmit(params) {
      inviteJoinTeam(params).then(response => {
        if (response && response.code === 0) {
          this.$message({
            message: this.$t('team.team_as_soon_as_possible3'),
            type: 'success'
          })
          this.saveLoading = false
          this.$emit('confirm-end')
        }
      }).catch(() => {
        this.saveLoading = false
      })
    },
    submitOption() {
      if (this.inviteType === 'phone') {
        this.submitInviteForm()
      } else {
        this.$emit('confirm-end')
      }
    },
    submitInviteForm() {
      var params = {}
      this.saveLoading = true
      if (this.inviteType === 'phone') {
        this.$refs['invitePhoneForm'].validate((valid) => {
          if (valid) {
            params.type = 1
            var phoneList = []
            for (var i = 0; i < this.invitePhoneForm.phones.length; i++) {
              var countryData = this.invitePhoneForm.phones[i].iti.getSelectedCountryData()
              phoneList.push({ country_code: countryData.dialCode, value: this.invitePhoneForm.phones[i].value })
            }
            params.phone_list = phoneList
            if (phoneList.length < 1) {
              this.$message({
                message: this.$t('team.please_fill_in_the_invitation_form'),
                type: 'error'
              })
              this.saveLoading = false
              return false
            }
            this.inviteJoinTeamSubmit(params)
          } else {
            this.saveLoading = false
          }
        })
      } else if (this.inviteType === 'mail') {
        this.$refs['inviteEmailForm'].validate((valid) => {
          if (valid) {
            params.type = 0
            var email_list = []
            for (var i = 0; i < this.inviteEmailForm.emails.length; i++) {
              email_list.push(this.inviteEmailForm.emails[i].value)
            }
            params.email_list = email_list
            if (email_list.length < 1) {
              this.$message({
                message: this.$t('team.please_fill_in_the_invitation_email'),
                type: 'error'
              })
              this.saveLoading = false
              return false
            }
            this.inviteJoinTeamSubmit(params)
          } else {
            this.saveLoading = false
          }
        })
      }
    },
    getInviteTeamCode(is_reset = 0) {
      this.loading = true
      getInviteCode({ is_reset: is_reset }).then(response => {
        this.loading = false
        if (response && response.code === 0) {
          this.buildInviteData(response.data)
        }
      }).catch(() => {
        this.loading = false
      })
    },
    getCurrTeamInfoData() {
      getCurrTeamInfo({}).then(response => {
        if (response && response.code === 0) {
          this.teamInfo = response.data
        }
      }).catch(() => {

      })
    },
    buildInviteData(data) {
      this.inviteInfo = data
      const code = data.invite_code
      const host = window.location.origin
      this.inviteLink = host + '/join-team/' + code
      this.inviteCode = code
      this.drawTeamQrcode()
    },
    drawTeamQrcode() {
      if (this.$refs.teamInviteQrCode) {
        this.$refs.teamInviteQrCode.innerHTML = ''
      }
      this.qrcode = new QRCode(this.$refs.teamInviteQrCode, {
        render: 'table',
        text: this.inviteLink,
        width: 200,
        height: 200,
        colorDark: '#333333', // 二维码颜色
        colorLight: '#ffffff', // 二维码背景色
        // src: this.websiteData.favicon_path,
        correctLevel: QRCode.CorrectLevel.L// 容错率，L/M/H
      })
      this.qrcode._oDrawing._elImage.onload = () => {
        if (this.qrcode._oDrawing._elImage.src && this.qrcode._oDrawing._elImage.src !== '') {
          this.qrCodeImgIsLoad = true
          this.drawimgObjects()
        }
      }
    },
    drawimgObjects() {
      // 画logo
      if (this.qrCodeImgIsLoad) {
        var imgData = this.qrcode._oDrawing._elImage
        var canvas = document.getElementById('canvas')

        var context = canvas.getContext('2d')
        // 清除画布
        context.clearRect(0, 0, canvas.width, canvas.height)
        context.drawImage(imgData, 0, 0, 200, 200)
        // 画公司logo
        /* if (this.websiteData.favicon_path != '') {
          /!*  //画矩形背景
          context.lineWidth = 50
          context.beginPath()
          context.moveTo(70, 70)
          context.lineTo(130, 70)
          context.lineTo(130, 130)
          context.lineTo(70, 130)
          context.closePath()
          context.fillStyle = '#f6f6fa'
          context.fill()*!/
          var qrCodeIco = document.getElementById('qrCodeIco')
          var whrate = qrCodeIco.width / qrCodeIco.height
          var drH = 50 / whrate
          var sh = 75
          if (drH < 50) {
            sh = sh + (50 - drH) / 2
          }
          context.drawImage(qrCodeIco, 75, sh, 50, drH)
        }*/
      }
    },
    downQrcodeImg() {
      const fbw_img_data = this.$refs.teamQrCodeCanvas.toDataURL('image/png')
      this.$refs.qrCodedownload.href = fbw_img_data
      this.$refs.qrCodedownload.click()
    },
    validatePhone(rule, value, callback) {
      if (value === '') {
        callback(new Error(this.$t('team.please_enter_your_mobile_number')))
      } else {
        var getIndexReg = rule.fullField.match(/\d+/)
        var isChina = false
        if (getIndexReg[0]) {
          if (this.invitePhoneForm.phones[getIndexReg[0]] && this.invitePhoneForm.phones[getIndexReg[0]].iti) {
            var countryData = this.invitePhoneForm.phones[getIndexReg[0]].iti.getSelectedCountryData()
            if (countryData && countryData.dialCode === '86') {
              isChina = true
            }
          }
        }
        if (isChina) {
          if (!/^1[3456789]\d{9}$/.test(value)) {
            callback(new Error(this.$t('team.the_correct_mobile_phone_number')))
          } else {
            callback()
          }
        } else {
          if (!/^\d+$/.test(value)) {
            callback(new Error(this.$t('team.the_correct_mobile_phone_number')))
          } else {
            callback()
          }
        }
      }
    },
    initTelInputSelect() {
      this.$nextTick(() => {
        if (this.invitePhoneForm.phones.length > 0) {
          for (var i = 0; i < this.invitePhoneForm.phones.length; i++) {
            var input = document.querySelector('#phone' + i)
            if (!input.dataset.intlTelInputId) {
              var iti = intlTelInput(input, {
                allowDropdown: true, // 是否下拉
                initialCountry: 'cn', // 默认国家
                autoHideDialCode: true,
                formatOnDisplay: true,
                nationalMode: false, // 是否隐藏区号
                placeholderNumberType: 'MOBILE',
                separateDialCode: true // 区号是否可输入
                // any initialisation options go here
              })
              this.invitePhoneForm.phones[i].iti = iti
            }
          }
        }
      })
    },
    removePhone(item) {
      var index = this.invitePhoneForm.phones.indexOf(item)
      if (index !== -1) {
        this.invitePhoneForm.phones.splice(index, 1)
      }
    },
    addPhone() {
      this.invitePhoneForm.phones.push({
        value: '',
        key: Date.now()
      })
      this.initTelInputSelect()
    },
    copyValue(message) {
      const input = document.createElement('textarea') // js创建一个input输入框
      input.value = message // 将需要复制的文本赋值到创建的input输入框中
      document.body.appendChild(input) // 将输入框暂时创建到实例里面
      input.select() // 选中输入框中的内容
      document.execCommand('Copy') // 执行复制操作
      document.body.removeChild(input) // 最后删除实例中临时创建的input输入框，完成复制操作
      this.$message({
        message: '复制成功',
        type: 'success'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.team-invitation-container {
  .team-qrcode {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    //top: -125px;
    .team-qrcode-box {
      display: block;
      position: relative;
      bottom: 0px;
      width: 135px;

      .team-qrcode-canvas {
        width: 100%;
      }

      .down-load-box {
        height: 135px;
        position: absolute;
        width: 100%;
        top: 0;
        cursor: pointer;
      }
    }
  }
  .invite-phone-box{
    min-height:250px;
    max-height:310px;
    overflow-y: auto;
    :deep(.el-input) {
      .iti {
        width: 100%;
        position: relative;

        .iti__selected-flag {
          background: transparent;
        }

        .iti__selected-flag {
          .iti__flag {
            //display: none;
          }
        }

        .iti__country-list {
          z-index: 200;
        }
      }
    }
    :deep(.iti) {
      .el-input__inner {
        //padding-left: 120px !important;
      }
    }
    :deep(.el-input__wrapper){
      transform: none;
    }
  }
  .re-set-box {
    height: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    padding-top: 20px;

    .reset-opt {
      padding-left: 10px;
      color: #409eff;
      cursor: pointer;
    }
  }
}

</style>

/** When your routing table is too long, you can split it into small modules **/

import Layout from '@/layout/index'

const chatRouter = {
  path: '/chat',
  component: Layout,
  redirect: '/chat/creation',
  name: 'Chat',
  meta: {
    title: 'router.chat-creation'
    // roles: ['user']
  },
  children: [
    {
      path: 'creation/:share_code?',
      component: () => import('@/views/chat/index.vue'), // Parent router-view
      name: 'ChatCreation',
      meta: {
        title: 'router.chat-creation'
        // roles: ['user.manage_user']
      }
    },
    {
      path: 'apps',
      component: () => import('@/views/chat/chat_apps.vue'), // Parent router-view
      name: 'ChatApps',
      meta: {
        title: 'router.chat-apps'
        // roles: ['user.manage_user']
      }
    },
    {
      name: 'ChatExport',
      path: 'export',
      meta: {title: 'router.chat-export'},
      component: () => import('@/views/chat/chat_export.vue')
    }
  ]
}
export default chatRouter

/** When your routing table is too long, you can split it into small modules **/

import Layout from '@/layout/index'
import agentLayout from '@/views/agent/index'

// 已废弃
const agentRouter = {
  path: '/agent',
  component: Layout,
  name: 'AgentIndex',
  redirect: '/agent/index',
  meta: {
    title: 'router.agent-index'
  },
  children: [
    {
      path: 'index',
      component: agentLayout,
      name: 'AgentIndex2',
      meta: {
        title: 'router.agent-index'
      },
      children: [
        {
          path: 'creation',
          component: () => import('@/views/agent/agent_creation.vue'),
          name: 'AgentCreation',
          meta: {
            title: 'router.agent-creation'
          }
        },
        {
          path: 'link',
          component: () => import('@/views/agent/agent_link.vue'),
          name: 'AgentLink',
          meta: {
            title: 'router.agent-link'
          }
        },
        {
          path: 'data-analysis',
          component: () => import('@/views/agent/agnet_data_analysis.vue'),
          name: 'AgentDataAnalysis',
          meta: {
            title: 'router.agent-data-analysis'
          }
        },
        {
          path: 'user-feedback',
          component: () => import('@/views/agent/agent_user_feedback.vue'),
          name: 'AgentUserFeedback',
          meta: {
            title: 'router.agent-user-feedback'
          }
        },
        {
          path: 'member-setting',
          component: () => import('@/views/agent/agent_member_setting.vue'),
          name: 'AgentMemberSetting',
          meta: {
            title: 'router.agent-member-setting'
          }
        }
      ]
    }
  ]
}
export default agentRouter

import { getUrlParam } from '@/utils/index'

const IS_DEVELOPMENT_ENV = process.env.NODE_ENV === 'development'
const IS_PRODUCTION_ENV = process.env.NODE_ENV === 'production'

/**
 * 页面跳转
 * @param {string} pathname 路径跳转
 * @param {object} queryObj 路径跳转携带参数
 */
export function goToPage(pathname, queryObj = {}) {
  let pathSting = ''
  if (IS_DEVELOPMENT_ENV) {
    pathSting = `/${pathname}`
  } else if (IS_PRODUCTION_ENV) {
    const path = location.pathname
    const prefix = path.slice(0, path.lastIndexOf('/'))
    pathSting = `${prefix}/${pathname}.html`
  }
  const queryString = Object.keys(queryObj)
    .reduce((prev, key) => [prev, `${key}=${queryObj[key]}`].join('&'), '')
    .slice(1)
  location.href = `${location.origin}${pathSting}${queryString ? `?${queryString}` : ''}`
}

/**
 * 获取语言
 * @returns language
 */
export function getLanguage() {
  const defLanguage = 'zh-cn'
  let language = getUrlParam('lang') || defLanguage
  language = language.replace(/_/, '-').toLowerCase()
  if (language === 'zh-cn' || language === 'zh') {
    language = 'zh-cn'
  } else if (language === 'en' || language === 'en-us' || language === 'en-gb') {
    language = 'en-us'
  }
  return language
}

export function getSite() {
  return 'agent_store'
}

/*
* 获取默认语言
* */
export function getDefLanguage() {
  // todo 获取浏览器默认语言
  return 'zh-cn'
}

/**
 * 获取Cookie前缀
 * @returns {string}
 */
export function getCookieKeyPrefix() {
  return 'agent_store_' + process.env.VUE_APP_ENV
}

export function elTableColumnAutoWidth(tableSelector) {
  const columnWidthList = {}
  const rows = document.querySelectorAll(
    tableSelector + ' .el-table__body .el-table__row'
  )
  const maxWidthCache = {}
  const ths = document.querySelectorAll(tableSelector + ' .el-table__header-wrapper th>div')
  for (let i = 0; i < ths.length; i++) {
    ths[i].style.textOverflow = 'unset'
    ths[i].style.whiteSpace = 'nowrap'
    ths[i].style.display = 'inline-block'
    ths[i].style.width = 'auto'
    ths[i].style.width = ths[i].scrollWidth + 'px'
    if (
      !maxWidthCache[i] ||
      maxWidthCache[i] < ths[i].scrollWidth
    ) {
      maxWidthCache[i] = ths[i].scrollWidth + 10
    }
  }
  rows.forEach((row) => {
    const tds = row.querySelectorAll('td>div')
    for (let i = 0; i < tds.length; i++) {
      tds[i].style.textOverflow = 'unset'
      tds[i].style.whiteSpace = 'nowrap'
      tds[i].style.display = 'inline-block'
      if (
        !maxWidthCache[i] ||
        maxWidthCache[i] < tds[i].scrollWidth
      ) {
        maxWidthCache[i] = tds[i].scrollWidth + 10
      }
    }
  })
  const firstTds = rows[0].querySelectorAll('td')
  let w = document.querySelector(tableSelector).clientWidth
  for (let i = 0; i < firstTds.length; i++) {
    if (maxWidthCache[i] < (w / (firstTds.length - i))) {
      maxWidthCache[i] = w / (firstTds.length - i)
    }
    columnWidthList[i] = maxWidthCache[i]
    w -= columnWidthList[i]
  }
  return columnWidthList
}

export function formatDate(dateString) {
  // 创建一个日期对象（假设输入的日期字符串是有效的ISO 8601格式）
  const date = new Date(dateString);

  // 提取年份、月份和日期（注意：月份是从0开始的，所以我们需要加1）
  const year = date.getFullYear(); // 我们不需要年份，但这里为了完整性还是提取了
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 转换为两位数字，必要时前面补0
  const day = date.getDate().toString().padStart(2, '0'); // 转换为两位数字，必要时前面补0

  // 构建所需的日期格式字符串
  const formattedDate = `${month}月${day}日`;

  return formattedDate;
}
